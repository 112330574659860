
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { Add, ResourcesLocation } from '@/types/restaurant'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { MapLocation, UploadFile }
})

export default class RestaurantAdd extends Vue {
  private info: Add = {
    projectId: '',
    cateName: '',
    startTime: '',
    endTime: '',
    description: '',
    resourceList: [],
    resourcesLocationList: []
  }

  private time: string[]|'' = ''

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    cateName: [
      { required: true, message: '请输入餐饮名称', trigger: ['blur', 'change'] }
    ],
    startTime: [
      { required: true, message: '请选择营业时间', trigger: 'change' }
    ],
    description: [
      { required: true, message: '请输入描述', trigger: ['blur', 'change'] }
    ],
    resourceList: [
      { required: true, message: '请上传图片', trigger: 'change' }
    ],
    resourcesLocationList: [
      { required: true, message: '请选择位置坐标', trigger: 'change' }
    ]
  }

  private submitShow = false
  private resourcesLocationList: ResourcesLocation[] | null = null
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.$route.params.id && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.restaurant.selectCateFoodById, {
      id: this.$route.params.id
    }).then(res => {
      this.time = [res.startTime, res.endTime]
      this.info = res || {}
      this.resourcesLocationList = res.resourcesLocationList
    })
  }

  locationChange (list: ResourcesLocation[]) {
    this.info.resourcesLocationList = list
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  // 展示时间
  changetimers (val: string[]) {
    console.log(val)
    if (val.length !== 0) {
      this.info.startTime = val[0]
      this.info.endTime = val[1]
    }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.id ? this.$apis.restaurant.updateCateFood : this.$apis.restaurant.insertCateFood
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'restaurantList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
